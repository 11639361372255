<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="allPrograms"
      sort-by="calories"
      class="elevation-1"
      :loading="isGettingData"
      loading-text="يتم جلب البيانات ...الرجاء الانتظار"
    >
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-text-field
              v-model="searchkey"
              label="تحديد رقم للبحث"
              prepend-icon="mdi-seach"
              clearable
            ></v-text-field>
          </td>
          <td>
            <v-autocomplete
              ref="classes"
              v-model="classId"
              :items="classes"
              placeholder="جلب حسب شعبة محدد"
              required
              item-text="name"
              item-value="id"
              chips
            ></v-autocomplete>
          </td>

          <td>
            <v-btn
              class="ma-2"
              outlined
              color="indigo"
              @click="searchTestPrograms"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="d-none d-sm-flex"
            >برامج المذاكرات</v-toolbar-title
          >
          <v-divider class="mx-4 d-none d-sm-flex" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            class="d-none d-sm-flex"
            @click="showAddNewPro = true"
            bottom
            color="blue darken-4"
            dark
            fab
            fixed
            left
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <!-- ******************************* Edit Dialoog*********************************-->
          <v-dialog v-model="dialog" max-width="300px">
            <v-card>
              <v-card-title class="accent white--text darken-2"
                >تعديل البيانات</v-card-title
              >
              <v-container>
                <v-row>
                  <div>
                    <v-card flat>
                      <v-card-text>
                        <v-form ref="form">
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <v-text-field
                                prepend-icon="mdi-account-circle"
                                placeholder="الاسم"
                                v-model="editedItem.num"
                                required
                              />
                            </v-col>
                          </v-row>
                          <v-row class="mb-2">
                            <v-col>
                              <v-autocomplete
                                ref="classes"
                                v-model="editedItem.classId"
                                :items="classes"
                                placeholder="الشعبة"
                                required
                                item-text="name"
                                item-value="id"
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer />

                <v-btn
                  color="success"
                  class="mr-4"
                  @click="save"
                  :loading="isClick"
                  >حفظ</v-btn
                >
                <v-btn text @click="dialog = false">الغاء</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--******************************** Detail Dialog*********************************-->

          <v-dialog
            v-model="detailDialog"
            fullscreen
            ref="di"
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="detailDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ program.num }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="updatePro">حفظ</v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-data-table
                :headers="detailHeaders"
                :items="program.details"
                sort-by="date"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>التفاصيل</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="innerDialog" max-width="500px">
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on"
                          >بند جديد</v-btn
                        >
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">بند جديد</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-form ref="form">
                              <v-row class="mb-6">
                                <v-col>
                                  <v-autocomplete
                                    v-model="detail.subjectId"
                                    :items="subjects"
                                    label="المادة"
                                    item-text="name"
                                    item-value="id"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    label="تاريخ"
                                    value="1/1/001"
                                    type="date"
                                    v-model="detail.date"
                                  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    label="التوقيت"
                                    value="08:00"
                                    type="time"
                                    v-model="detail.time"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="addDetail()"
                            >حفظ</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="innerDialog = false"
                            >إغلاق</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn
                    rounded
                    color="error"
                    @click="deleteProDetail(item, program.id)"
                    >حذف</v-btn
                  >
                </template>
                <template v-slot:no-data
                  >لم يتم تحديد تفاصيل لهذا البرنامج</template
                >
              </v-data-table>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!--******************************************-->
      <template v-slot:item.action="{ item }">
        <v-btn rounded color="primary" dark @click="editProgram(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn rounded class="mr-2" color="error" @click="deleteProgram(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          rounded
          class="mr-2"
          color="success"
          @click="editProgramDetail(item)"
          >تعديل التفاصيل</v-btn
        >
      </template>

      <template v-slot:no-data>لا يوجد أي برنامج محدد مسبقاً</template>
    </v-data-table>
    <AddTestProgram :show.sync="showAddNewPro" @programAdded="refreshData()" />
  </div>
</template>

<script>
import { APIService } from "../../services/apiServices";
import AddTestProgram from "../testPrograms/AddTestProgram.vue";
const apiService = new APIService();
export default {
  name: "TestProgramIndex",
  components: { AddTestProgram },
  data: () => ({
     isGettingData :true,
    dialog: false,
    detailDialog: false,
    innerDialog: false,
    isClick: false,
    classId: 0,
    searchkey: "",
    showAddNewPro: false,
    headers: [
      {
        text: "رقم البرنامج",
        align: "start",

        value: "num",
      },
      { text: "الشعبة", value: "className" },

      { text: "العمليات", value: "action", sortable: false },
    ],
    detailHeaders: [
      {
        text: "المادة",
        align: "start",
        value: "subjectName",
      },
      { text: "التاريخ", value: "date" },
      { text: "التوقيت", value: "time" },

      { text: "العمليات", value: "action", sortable: false },
    ],
    allPrograms: [],
    classes: [],
    subjects: [],
    editedIndex: -1,
    program: {
      id: 0,
      num: "",
      details: [],
    },
    editedItem: {
      id: 0,
      num: "",
      classId: "0",
    },
    defaultItem: {
      id: 0,
      num: "",
      classId: "0",
    },
    detail: {
      id: 0,
      subjectId: 0,
      date: "",
      time: "",
    },
  }),
  created() {
    this.getTestPrograms();
    this.getClasses();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "مادة جديدة" : "تعديل";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    detailDialog(val) {
      if (val) {
        this.getSubjects();
      }
    },
  },

  methods: {
    getTestPrograms() {
      this.isGettingData = true;
      const that = this;
      apiService
        .getTestPrograms()
        .then((response) => {
          that.isGettingData = false;
          this.allPrograms = response.programs;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },
    getClasses() {
      apiService
        .getClasses()
        .then((response) => {
          this.classes = response.classes;
        })
        .catch((error) => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
        });
    },
    searchTestPrograms() {
      this.isGettingData = true;
      const that = this;
      apiService
        .searchTestProgram(this.classId, this.searchkey)
        .then((response) => {
          that.isGettingData = false;
          this.allPrograms = response.programs;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },
    addDetail() {
      var d = this.detail;
      d.subjectName = this.subjects.find(
        (s) => s.id === this.detail.subjectId
      ).name;
      // this.allPrograms.find(c => c.id === id).details.push(d);
      this.program.details.push(d);
      this.detail = {
        id: 0,
        subjectId: 0,
        date: "",
        time: "",
      };

      this.innerDialog = false;
    },
    editProgram(item) {
      this.editedIndex = this.allPrograms.indexOf(item);
      //this.editedItem. = Object.assign({}, item);
      this.editedItem.id = item.id;
      this.editedItem.num = item.num;
      this.editedItem.classId = item.classId;
      this.dialog = true;
    },
    editProgramDetail(item) {
      this.program.id = item.id;
      this.program.num = item.num;
      // this.program.classId = item.classId;
      this.program.details = item.details;
      this.detailDialog = true;
    },
    deleteProgram(item) {
      var testProgramObject = {
        Id: item.id,
      };
      if (confirm("هل تريد بالتأكيد حذف البرنامج مع كافة تفاصيله")) {
        setTimeout(() => {
          apiService
            .deleteTestProgram(testProgramObject)
            .then(() => {
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
      }
    },
    updatePro() {
      var that = this;
      setTimeout(() => {
        apiService
          .updateTestProgramDetail(that.program)
          .then(() => {
            this.detailDialog = false;
          })
          .catch((error) => {
            this.error = "حدث خطأ ";
            alert(error);
            this.failed();
          });
      }, 3000);
    },

    getSubjects() {
      apiService
        .getSubjects()
        .then((response) => {
          this.subjects = response.subjects;
        })
        .catch((error) => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
        });
    },
    refreshData() {
      this.getTestPrograms();
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        this.isClick = true;
        setTimeout(() => {
          apiService
            .updateTestProgram(this.editedItem)
            .then(() => {
              this.isClick = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              this.close();
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
      }
    },
    deleteProDetail(item, id) {
      if (confirm("هل تريد بالتأكيد حذف هذا البند")) {
        const index = this.allPrograms
          .find((c) => c.id === id)
          .details.indexOf(item);
        if (index > -1) {
          //this.allPrograms.find(c => c.id === id).details.splice(index, 1);
          this.program.details.splice(index, 1);
        }
      }
    },
  },
};
</script>
